import * as Dialog from '@radix-ui/react-dialog';
import { ReactNode, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import {
  Campaign,
  CampaignTask,
  EAndOr,
  EPlatforms,
  TaskType,
} from 'views/types/tasks';
import { CampaignFormScheme } from '../../scheme/taksForm';
import { CampaignForm } from './Form';
import { MessForm } from './MessageForm';
import { campaignService } from 'views/services/campaignService';
import { tasksService } from 'views/services/tasksService';
import { TaskDialog } from 'views/admin/marketplace/components/TaskDialog';

interface CampaignDialogProps {
  campaign?: Campaign;
  children: ReactNode;
  formType?: 'campaign' | 'message';
}

export const CampaignDialog = ({
  campaign,
  children,
  formType,
}: CampaignDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChangedImage, setIsChangedImage] = useState(false);
  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false);
  const [task, setTask] = useState<CampaignTask>();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const isEdit = !!campaign;
  const hasQuiz = campaign?.tasks
    ? campaign?.tasks?.find(task => task.type === TaskType.QUIZ)
    : null;
  const hasStory = campaign?.tasks
    ? campaign?.tasks?.find(task => task.type === TaskType.STORY)
    : null;

  const form = useForm<CampaignFormScheme>({
    values: {
      title: isEdit ? campaign?.title : '',
      description: isEdit ? campaign?.description : '',
      isActive: isEdit ? campaign?.isActive : false,
      imageUrl: isEdit ? campaign?.imageUrl : '',
      isUsersWithWalletOnly: isEdit ? campaign?.isUsersWithWalletOnly : false,
      isUsersWithoutWalletOnly: isEdit
        ? campaign?.isUsersWithoutWalletOnly
        : false,
      targetGenders: isEdit ? campaign?.targetGenders : undefined,
      targetMinAge: isEdit ? campaign?.targetMinAge : null,
      targetMaxAge: isEdit ? campaign?.targetMaxAge : null,
      targetCountries: isEdit
        ? Object.fromEntries(
            campaign?.targetCountries.map(item => [item || '', true])
          )
        : {},
      targetUtmSources:
        isEdit && campaign?.targetUtmSources
          ? Object.fromEntries(
              campaign.targetUtmSources.map(item => [item || '', true])
            )
          : {},
      languagesAndCountries: isEdit
        ? campaign?.languagesAndCountries
          ? EAndOr.AND
          : EAndOr.OR
        : EAndOr.OR,
      targetLanguages: isEdit
        ? Object.fromEntries(
            campaign?.targetLanguages.map(item => [item || '', true])
          )
        : {},
      isTgPremiumOnly: isEdit ? campaign?.isTgPremiumOnly : false,
      onlyForIos: isEdit
        ? campaign?.targetPlatforms.includes(EPlatforms.ios)
        : false,
      isDuckPremiumOnly: isEdit ? campaign?.isDuckPremiumOnly : false,
      endDate: isEdit
        ? new Date(campaign?.endDate).toISOString().split('T')[0]
        : '',
      approximateTime: isEdit ? campaign.approximateTime : '0',
      usersCompletedCountMax: campaign?.usersCompletedCountMax,
      rewardAmount: isEdit ? campaign.rewardAmount : '0',
      withNotification: isEdit ? campaign.withNotification : false,
    },
  });

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    setIsChangedImage(false);

    if (!isOpen) {
      form.reset();
      setTask(undefined);
    }
  };

  const handleCloseModal = (isOpen: boolean) => {
    setIsOpenTaskModal(isOpen);
    if (!isOpen) {
      setTask(undefined);
    }
  };

  const handleSubmit = async (data: CampaignFormScheme) => {
    try {
      if (formType === 'message') {
        const response = await tasksService.createMessage({
          message: data.task.message,
          images: data.task.images,
        });
        if (!response) return;
      } else if (formType === 'campaign') {
        if (isEdit) {
          await campaignService.updateCampaign(data, campaign.id);
        } else {
          await campaignService.createCampaign(data);
        }
      }
      form.reset();
      handleOpenChange(false);
      setTask(undefined);
    } catch (error) {
      console.warn('Error saving task', error);
    }
  };

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={handleOpenChange} modal>
        <Dialog.Trigger asChild>{children}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content
            onInteractOutside={e => {
              if ((e.target as HTMLElement).closest('.chakra-toast')) {
                e.preventDefault();
              }
            }}
            className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[800px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white"
          >
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <Dialog.Title className="text-mauve12 mb-10 text-[17px] font-medium">
                {isEdit
                  ? 'Edit Campaign'
                  : formType === 'campaign'
                    ? 'Create Campaign'
                    : 'Create Message'}
              </Dialog.Title>
              <div className="max-h-[55dvh] grow overflow-y-auto overflow-x-hidden px-px pr-4">
                <FormProvider {...form}>
                  {formType === 'message' ? (
                    <MessForm />
                  ) : (
                    <CampaignForm
                      isEdit={isEdit}
                      campaign={isEdit && campaign}
                      setIsChangedImage={setIsChangedImage}
                      setIsOpenTaskModal={setIsOpenTaskModal}
                      setTask={setTask}
                    />
                  )}
                </FormProvider>
              </div>
              <div className="mt-[10px] flex flex-col">
                {form.formState.errors.root && (
                  <span className="text-sm capitalize text-red-500 dark:text-red-400">
                    {form.formState.errors.root.message}
                  </span>
                )}
                <button
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled={
                    form.formState.isSubmitting ||
                    (!form.formState.isDirty && !isChangedImage) ||
                    isLoadingImage
                  }
                >
                  {form.formState.isSubmitting ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </form>
            <TaskDialog
              formType="task"
              onOpenChange={handleCloseModal}
              campaignId={!!campaign && campaign.id}
              hasQuiz={!!hasQuiz}
              hasStory={!!hasStory}
              isFormOpen={isOpenTaskModal}
              task={task}
              isLoadingImage={isLoadingImage}
              setIsLoadingImage={setIsLoadingImage}
            >
              <div />
            </TaskDialog>
            <Dialog.Close asChild>
              <button
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
