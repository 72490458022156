import { createStandaloneToast } from '@chakra-ui/toast';
import { AxiosError } from 'axios';
import { $api } from './interceptor';
import { CampaignFormScheme } from 'views/admin/campaigns/scheme/taksForm';
import { campaignStore } from 'views/store/useCampaignStore';
import { TAnaliticsData } from 'views/types/analitics';
import { EAndOr, EPlatforms } from 'views/types/tasks';

export type SetPagesCount = (count: number) => void;

export const { toast } = createStandaloneToast();

const CampaignApi = () => {
  const { setState } = campaignStore;
  const getCampaigns = async () => {
    const { data } = await $api.get('/api/campaigns/list');
    setState({ campaignList: data });
  };

  const getCampaignAnalytics = async (campaignId: string) => {
    const { data } = await $api.get<TAnaliticsData>(
      `/api/campaigns/stat/${campaignId}`
    );
    return data;
  };

  const createCampaign = async (payload: CampaignFormScheme) => {
    const targetGenders =
      typeof payload.targetGenders === 'string'
        ? [payload.targetGenders]
        : payload.targetGenders;
    const formattedPayload = {
      ...payload,
      usersCompletedCountMax: +payload?.usersCompletedCountMax || null,
      rewardAmount: +payload.rewardAmount,
      approximateTime: +payload.approximateTime,
      isUsersWithWalletOnly: payload.isUsersWithWalletOnly,
      isUsersWithoutWalletOnly: payload.isUsersWithoutWalletOnly,
      targetMinAge: +payload.targetMinAge || null,
      targetMaxAge: +payload.targetMaxAge || null,
      targetGenders: payload?.targetGenders ? targetGenders : [],
      targetCountries: Object.entries(payload.targetCountries)
        .filter(([_, value]) => value)
        .map(([key, _]) => key),
      targetUtmSources: Object.entries(payload.targetUtmSources)
        .filter(([_, value]) => value)
        .map(([key, _]) => key),
      targetLanguages: Object.entries(payload.targetLanguages)
        .filter(([_, value]) => value)
        .map(([key, _]) => key),
      languagesAndCountries: payload.languagesAndCountries === EAndOr.AND,
    };

    try {
      await $api.post('/api/campaigns', formattedPayload);
      toast({
        title: 'Campaign successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
      await getCampaigns();
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        const errorData = error?.response?.data;
        const message = (errorData as { message: string })?.message;
        toast({
          title: 'Something went wrong.',
          description: message || 'Please check form data',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Something went wrong.',
          description: 'Please check form data',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const updateCampaign = async (
    payload: CampaignFormScheme,
    campaignID: string,
    setIsActive = false
  ) => {
    const targetGenders =
      typeof payload.targetGenders === 'string'
        ? [payload.targetGenders]
        : payload.targetGenders;
    const formattedPayload = {
      ...payload,
      usersCompletedCountMax: +payload.usersCompletedCountMax || null,
      rewardAmount: +payload.rewardAmount,
      approximateTime: +payload.approximateTime,
      usersWithWalletOnly: payload.isUsersWithWalletOnly,
      usersWithoutWalletOnly: payload.isUsersWithoutWalletOnly,
      targetPlatforms: payload.onlyForIos ? [EPlatforms.ios] : [],
      targetMinAge: +payload.targetMinAge || null,
      targetMaxAge: +payload.targetMaxAge || null,
      targetGenders: payload?.targetGenders.length ? targetGenders : [],
      targetCountries: setIsActive
        ? payload.targetCountries
        : Object.entries(payload.targetCountries)
            .filter(([_, value]) => value)
            .map(([key, _]) => key),
      targetLanguages: setIsActive
        ? payload.targetLanguages
        : Object.entries(payload.targetLanguages)
            .filter(([_, value]) => value)
            .map(([key, _]) => key),
      targetUtmSources: setIsActive
        ? payload.targetUtmSources
        : Object.entries(payload.targetUtmSources)
            .filter(([_, value]) => value)
            .map(([key, _]) => key),
      languagesAndCountries: payload.languagesAndCountries === EAndOr.AND,
    };

    try {
      await $api.patch(`/api/campaigns/${campaignID}`, formattedPayload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast({
        title: 'Campaign successfully updated.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
      await getCampaigns();
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorData = error?.response?.data;
        const message = (errorData as { message: string })?.message;
        toast({
          title: 'Something went wrong.',
          description: message || 'Please check form data',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Something went wrong.',
          description: 'Please check form data',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const deleteCampaign = async (campaignID: string) => {
    try {
      const response = await $api.delete(`api/campaigns/${campaignID}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        toast({
          title: 'Campaign successfully deleted.',
          status: 'success',
          isClosable: true,
          position: 'top-right',
        });

        getCampaigns();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Something went wrong.',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Something went wrong.',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const uploadAdminURL = async (image: FormData) => {
    try {
      const response = await $api.post<
        [
          {
            fileUrl: string;
            filename: string;
          },
        ]
      >(`api/files/admin/upload`, image, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data[0].fileUrl;
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Failed to upload image',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Failed to upload image',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      console.log('upload image error:', error);
      throw error;
    }
  };

  return {
    getCampaigns,
    getCampaignAnalytics,
    createCampaign,
    deleteCampaign,
    uploadAdminURL,
    updateCampaign,
  };
};

export const campaignService = CampaignApi();
