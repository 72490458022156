import { TaskType } from 'views/types/tasks';

type TCampaignTasksOrder = {
  [key in TaskType]: string | undefined;
};

export const DEFAULT_CAMPAIGN_TASKS_ORDER: TCampaignTasksOrder = {
  [TaskType.STORY]: '1',
  [TaskType.YOUTUBE]: '2',
  [TaskType.TELEGRAM]: undefined,
  [TaskType.SNAPSHOT]: undefined,
  [TaskType.QUIZ]: undefined,
  [TaskType.COMMON]: undefined,
  [TaskType.TRANSACTION]: undefined,
  [TaskType.WALLET]: undefined,
  [TaskType.REFERRAL]: undefined,
  [TaskType.BIO]: undefined,
};
