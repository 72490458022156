import { createStandaloneToast } from '@chakra-ui/toast';
import { $api } from './interceptor';
import { AxiosError } from 'axios';
import { TagFormScheme } from 'views/admin/tags/scheme/form';
import { TTag } from 'views/types/utm';

export const { toast } = createStandaloneToast();

const tagsApi = () => {
  const getPaginatedTags = async () => {
    const { data } = await $api.get<TTag[]>('/api/tags?isFullResponse=true');

    return { data };
  };

  const createTag = async (tag: TagFormScheme) => {
    try {
      const data = { ...tag, description: tag.name };
      await $api.post('/api/tags', data);
      toast({
        title: 'Tag created',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: error.response?.data.message || 'Error creating Tag',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error creating Tag',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const editTag = async (id: string, data: TagFormScheme) => {
    try {
      await $api.patch(`/api/tags/${id}`, data);
      toast({
        title: 'Tag updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: error.response?.data.message || 'Error updating Tag',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error updating Tag',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const deleteTag = async (id: string) => {
    try {
      await $api.delete(`/api/tags/${id}`);
      toast({
        title: 'Tag deleted',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: error.response?.data.message || 'Error deleting Tag',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error deleting Tag',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  return {
    createTag,
    deleteTag,
    editTag,
    getPaginatedTags,
  };
};

export const tagsService = tagsApi();
