import { $api } from './interceptor';

export type TExtraAnaliticsDataTypes =
  | 'age'
  | 'points'
  | 'accountAge'
  | 'completedTasks'
  | 'completedCampaigns'
  | 'referrals';

export type TAnaliticsTypes = 'task' | 'campaign' | 'utmSource' | 'empty';

type TExtraAnaliticsOptions = {
  dataType: TExtraAnaliticsDataTypes;
  analityctsType: TAnaliticsTypes;
  itemId: string;
  from?: number;
  to?: number | '';
  taskId?: string;
};

const analiticsApi = () => {
  const getExtraAnalitics = async (options: TExtraAnaliticsOptions) => {
    const idOption =
      options.analityctsType === 'empty'
        ? ''
        : `&${options.analityctsType}Id=${options.itemId}`;
    const taskIdOption = options.taskId ? `&taskId=${options.taskId}` : '';
    const { data } = await $api.get<{ usersCount: number }>(
      `/api/users/specified-statistic?dataType=${options.dataType}${idOption}&fromValue=${options.from ?? ''}&toValue=${options.to ?? ''}${taskIdOption}`
    );
    return data;
  };

  return {
    getExtraAnalitics,
  };
};

export const analiticsService = analiticsApi();
