import { MdGroup } from 'react-icons/md';

import Widget from 'components/widget/Widget';
import UsersTable from 'views/admin/default/components/UsersTable';

import useSWR from 'swr';
import { usersService } from 'views/services/usersService';
import { combosService } from 'views/services/comboService';
import { Button } from 'components/button';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import { useEffect } from 'react';

const Dashboard = () => {
  const {
    setAnalitic,
    setAppStatistics,
    type: analiticsType,
  } = useAnaliticsStore();
  const { data: statistics, isLoading } = useSWR(
    '/users-statistics',
    () => usersService.getUsersStatistic(),
    {
      refreshInterval: 5000,
    }
  );

  const { data: combo } = useSWR('/combo', () =>
    combosService.getCurrentCombo()
  );

  const handleAnalyticsClick = () => {
    setAnalitic(EAnaliticDataTypeEnum.app, 'null', 'App statistics');
  };

  useEffect(() => {
    if (analiticsType === 'app') {
      setAppStatistics(statistics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistics, analiticsType]);

  return (
    <div className="flex flex-col">
      <div className="h-max mt-3 w-full flex flex-col md:flex-row gap-4 items-center justify-between">
        <div className="w-full flex gap-2 wrap">
          <Widget
            icon={<MdGroup className="h-7 w-7" />}
            title={'Total users'}
            subtitle={
              isLoading ? 'Loading...' : statistics?.totalCount?.toString()
            }
          />
          <Button
            className="ml-auto mt-auto h-fit"
            onClick={handleAnalyticsClick}
          >
            Analytics
          </Button>
        </div>

        {!!combo && (
          <div className="capitalize dark:text-white text-lg font-semibold flex flex-col items-center">
            Today secret combo:
            <p className="opacity-70 text-md">{combo.sequence.join('-')}</p>
          </div>
        )}
      </div>
      <div className="mt-5 grid grid-cols-1 gap-5 grow min-w-full">
        <div className="w-full">
          <UsersTable />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
