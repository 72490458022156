import { getDateDifference } from 'utils/date';
import { TAnaliticsData } from 'views/types/analitics';
import {
  renderCountriesData,
  renderDevicesData,
  renderGenderData,
  renderLanguagesData,
  renderPremiumPlansData,
  renderSelectedCountriesData,
  renderUsersFilledBioData,
  renderUtmSourcesData,
  renderWalletsData,
} from './helpers';
import { ExtraAnaliticsFields } from './ExtraAnaliticsFields';
import { TAnaliticsTypes } from 'views/services/analitics';
import { formatNumber } from 'utils/formatNumber';

type TNonAppAnaliticsProps = {
  data: TAnaliticsData | null;
  type: string;
  title: string;
  id: string;
  extraAnaliticsType: TAnaliticsTypes;
};

export const NonAppAnalitics = ({
  data,
  type,
  title,
  id,
  extraAnaliticsType,
}: TNonAppAnaliticsProps) => {
  return (
    <>
      {data && (
        <div className="w-full flex flex-col gap-8 max-h-[80svh] overflow-y-auto  mt-4">
          <div>
            <h2 className="text-2xl font-semibold mb-2 text-3xl border-b border-gray-200 dark:!border-white/10 pb-2">
              Analytics:
            </h2>
            <h3 className="text-2xl font-semibold mb-8 text-lg">
              <span className="uppercase mr-3">{type}:</span>
              {title}
            </h3>
            {data?.availableUsersCount >= 0 &&
              data.completedUsersCount >= 0 && (
                <div className="flex gap-8">
                  <div className="flex gap-2">
                    <h3 className="min-w-[100px] font-bold">
                      Users Available:
                    </h3>
                    <p>{data.availableUsersCount}</p>
                  </div>

                  <div className="flex gap-2">
                    <h3 className="min-w-[100px] font-bold">
                      Users Completed:
                    </h3>
                    <p>{data.completedUsersCount}</p>
                  </div>
                </div>
              )}
            {data?.totalUsersCount >= 0 && (
              <div className="flex gap-8">
                <div className="flex gap-2">
                  <h3 className="min-w-[100px] font-bold">Total Users:</h3>
                  <p>{data.totalUsersCount}</p>
                </div>
              </div>
            )}
            {data?.totalUsersCount >= 0 && data?.totalUsersData && (
              <div className="flex gap-8">
                <div className="flex gap-2">
                  <h3 className="min-w-[100px] font-bold">
                    Percent from all users:
                  </h3>
                  <p>{formatNumber(data.totalUsersData.usersPercent)}%</p>
                </div>
              </div>
            )}
            {data.usersAvgTgAge && (
              <div className="flex gap-8">
                <div className="flex gap-2">
                  <h3 className="min-w-[100px] font-bold">Users AVG TG Age:</h3>
                  <p>{`${getDateDifference(data.usersAvgTgAge)}`}</p>
                </div>
              </div>
            )}
          </div>

          <div className="border-b border-gray-200 dark:!border-white/10 -mt-4" />

          {data.devicesData && renderDevicesData(data.devicesData)}
          {renderGenderData(data.genderData)}
          {renderCountriesData(data.countriesData)}
          {data?.selectedCountriesData &&
            renderSelectedCountriesData(
              data.selectedCountriesData,
              data.completedUsersCount || data.totalUsersCount
            )}
          {renderLanguagesData(data.languagesData)}
          {renderPremiumPlansData(
            data.duckPremiumData,
            data.tgPremiumData,
            data.totalUsersCount,
            data.completedUsersCount
          )}
          {data?.utmSourcesData &&
            renderUtmSourcesData(
              data.utmSourcesData,
              data.completedUsersCount || data.totalUsersCount
            )}
          {renderWalletsData(
            data.walletsData,
            data.completedUsersCount,
            data.totalUsersCount
          )}
          {data.usersFilledBioData &&
            renderUsersFilledBioData(
              data.usersFilledBioData,
              data.totalUsersCount || data.completedUsersCount
            )}
          <ExtraAnaliticsFields
            resourceId={id}
            totalUsers={data.completedUsersCount || data.totalUsersCount}
            type={extraAnaliticsType}
          />
        </div>
      )}
    </>
  );
};
