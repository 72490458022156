import Card from 'components/card';
import { useEffect, useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Header,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import useSWR from 'swr';
import { useDebounceValue } from 'usehooks-ts';
import { usersService } from 'views/services/usersService';
import { User } from 'views/types/typeUser';
import Pagination from '../../../../../components/Pagination/Pagination';
import { BiCaretUp } from 'react-icons/bi';
import { BiCaretDown } from 'react-icons/bi';
import InputField from 'components/fields/InputField';
import Switch from 'components/switch';
import Select from 'components/fields/Select';
import { utmService } from 'views/services/utmService';
import { booleanOptions } from './types';
import { genderOptions } from './types';
import getCountries from 'views/services/getCountries';
import getLanguages from 'views/services/getLanguages';
import getPremiumPlans from 'views/services/getPremiumPlans';
import { ButtonSM } from 'components/button/Small';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import { formatDateToMonthAndYear } from 'utils/date';

function UsersTable() {
  const { setAnalitic } = useAnaliticsStore();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState('');
  const [referralLinkSearch, setReferralLinkSearch] = useState('');
  const [searchField] = useState('username');
  const [page, setPage] = useState(0);
  const [searchDebounce] = useDebounceValue(search, 1000);
  const [referralLinkSearchDebounce] = useDebounceValue(
    referralLinkSearch,
    1000
  );
  const [stopAutoRefresh] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [UTMSources, setUtmSources] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [languagesList, setLanguageList] = useState([]);
  const [selectedUTMSource, setSelectedUTMSource] = useState<
    string | undefined
  >();
  const [selectedTgPremiumStatus, setSelectedTgPremiumStatus] = useState<
    string | undefined
  >();
  const [premiumPlansList, setPremiumPlansList] = useState([]);
  const [selectedGender, setSelectedGender] = useState<string | undefined>();
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
  const [selectedUserCountry, setSelectedUserCountry] = useState<
    string | undefined
  >();
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<
    string | undefined
  >();
  const [selectedLanguage, setSelectedLanguage] = useState<
    string | undefined
  >();

  let sortingQuery: string;
  const sortingValue = sorting?.[0];
  if (sortingValue) {
    switch (sortingValue.id) {
      case 'referralCount':
        sortingQuery = 'referral_count:';
        break;
      case 'points':
        sortingQuery = 'clicks_count:';
        break;
      case 'completedTasks':
        sortingQuery = 'done_tasks_amount:';
        break;
      case 'age':
        sortingQuery = 'age:';
        break;
      case 'completedCampaigns':
        sortingQuery = 'done_campaigns_amount:';
        break;
      case 'tgAccountCreationDate':
        sortingQuery = 'telegram_user_id:';
        break;
    }
    sortingQuery += sortingValue.desc ? 'DESC' : 'ASC';
  }

  const selectedUTMSourceId = UTMSources?.find(
    source => source.name == selectedUTMSource
  )?.id;

  const { data: tableData, isLoading } = useSWR(
    `/users?page=${page}&searchField=${searchField}&search=${searchDebounce}&searchBy=telegram_user_id&limit=${itemsPerPage}&sortBy=${sortingQuery}&filter.utm_source.id=${selectedUTMSourceId}&filter.is_premium=${selectedTgPremiumStatus}&filter.gender=${selectedGender}&filter.country_id=${selectedCountry}&filter.selected_country_id=${selectedUserCountry}&filter.telegram_user_id=${referralLinkSearchDebounce}&filter.premium_subscription_id=${selectedSubscriptionPlan}&filter.language_code=${selectedLanguage}`,
    () =>
      usersService.getPaginatedUsersList({
        page: page,
        utmSource: selectedUTMSourceId,
        country: selectedCountry,
        languageCode: selectedLanguage,
        userCountry: selectedUserCountry,
        tgPremiumStatus: selectedTgPremiumStatus,
        subscriptionPlan: selectedSubscriptionPlan,
        gender: selectedGender,
        pageSize: itemsPerPage,
        searchField: searchField,
        searchValue: searchDebounce,
        sortingValue: sortingQuery,
        referralLinkFilter: referralLinkSearchDebounce,
      }),
    {
      refreshInterval: 0,
      revalidateOnFocus: !stopAutoRefresh,
    }
  );

  const handleEverlastingDuckPremium = async (
    value: boolean,
    userId: string
  ) => {
    await usersService.upgradeUserDuckPremiumStatus(userId, value);
  };

  const handleCustomIncomePercent = async (value: number, userId: string) => {
    if (!isNaN(value)) {
      await usersService.upgradeUserIncomePercent(userId, value);
    }
  };

  const handleAnaliticsModal = (userId: string, userName: string) => {
    setAnalitic(EAnaliticDataTypeEnum.referral, userId, userName);
  };

  const renderTags = (tags: string[]) => {
    if (!tags) return <span className="text-gray-500">No Tags</span>;
    return tags.map(
      (tag, index, arr) => tag + (index < arr.length - 1 ? ', ' : '')
    );
  };

  const columns = [
    columnHelper.accessor('telegramUserId', {
      id: 'telegramUserId',
      header: () => (
        <p className="w-[150px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
          Unique TelegramId
        </p>
      ),
      cell: info => (
        <p className="pl-4 w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('utmSource', {
      id: 'utmSource',
      header: () => (
        <p className="w-[280px] min-w-[280px] text-sm font-bold text-gray-600 dark:text-white">
          <Select
            variant="auth"
            extra="w-full mb-3"
            id="utm-source"
            onChange={e => {
              setSelectedUTMSource(e.target.value);
            }}
          >
            <option onClick={() => setSelectedUTMSource(undefined)}>
              Select UTM Source
            </option>
            {Object.values(UTMSources).map(source => (
              <option
                key={source?.id}
                value={source?.name}
                selected={selectedUTMSourceId == source?.id}
              >
                {source?.name}
              </option>
            ))}
          </Select>
        </p>
      ),
      cell: info => (
        <div className="flex w-[240px] min-w-[240px] items-center">
          {info.cell.getValue() || (
            <span className="text-gray-500">Another traffic source</span>
          )}
        </div>
      ),
    }),
    columnHelper.accessor('telegramName', {
      id: 'telegramName',
      header: () => (
        <p className="w-[200px] min-w-[200px] text-sm font-bold text-gray-600 dark:text-white">
          TG Name
        </p>
      ),
      cell: info => (
        <div className="flex w-[200px] min-w-[200px] items-center">
          <a
            href={`http://t.me/${info.row.original.username}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-bold text-navy-700 underline dark:text-white"
          >
            {info.cell.getValue()}
          </a>
        </div>
      ),
    }),
    columnHelper.accessor('tagNames', {
      id: 'tagNames',
      header: () => (
        <p className="w-[200px] min-w-[200px] text-sm font-bold text-gray-600 dark:text-white">
          TAG
        </p>
      ),
      cell: info => (
        <div className="flex w-[200px] min-w-[200px] items-center">
          {renderTags(info.cell.getValue())}
        </div>
      ),
    }),
    columnHelper.accessor('username', {
      id: 'username',
      header: () => (
        <p className=" w-[200px] min-w-max text-sm font-bold text-gray-600 dark:text-white">
          TG Username
        </p>
      ),
      cell: info => (
        <div className="flex w-[200px] min-w-max items-center">
          <a
            href={`http://t.me/${info.row.original.username}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-bold text-navy-700 underline dark:text-white"
          >
            {info.cell.getValue()}
          </a>
        </div>
      ),
    }),

    columnHelper.accessor('tgAccountCreationDate', {
      id: 'tgAccountCreationDate',
      header: () => (
        <p
          className="w-[150px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white"
          onClick={() =>
            sortingValue?.id == 'tgAccountCreationDate' && setSorting([])
          }
        >
          TG Age
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {formatDateToMonthAndYear(info.cell.getValue().toString())}
        </p>
      ),
    }),

    columnHelper.accessor('last_device', {
      id: 'last_device',
      header: () => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
          System
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">No Data</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('gender', {
      id: 'gender',
      header: () => (
        <p className="w-[130px] min-w-[130px] text-sm font-bold text-gray-600 dark:text-white">
          <Select
            variant="auth"
            extra="w-full mb-3"
            id="gender"
            onChange={e => {
              if (genderOptions.some(option => option.name == e.target.value)) {
                setSelectedGender(e.target.value);
              } else {
                setSelectedGender(undefined);
              }
            }}
          >
            <option>Sex</option>
            {Object.values(genderOptions).map(option => {
              return (
                <option
                  key={option?.id}
                  value={option?.name}
                  selected={selectedGender == option?.name}
                >
                  {option?.name}
                </option>
              );
            })}
          </Select>
        </p>
      ),
      cell: info => (
        <p className="w-[130px] min-w-[130px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">Not Selected</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('age', {
      id: 'age',
      header: () => (
        <p
          className="w-[120px] min-w-[120px] text-sm font-bold text-gray-600 dark:text-white"
          onClick={() => sortingValue?.id == 'age' && setSorting([])}
        >
          Age
        </p>
      ),
      cell: info => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">Unknown</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('completedTasks', {
      id: 'completedTasks',
      header: () => (
        <p
          className="w-[120px] min-w-[120px] text-sm font-bold text-gray-600 dark:text-white"
          onClick={() => sortingValue?.id == 'completedTasks' && setSorting([])}
        >
          Completed Tasks
        </p>
      ),
      cell: info => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('completedCampaigns', {
      id: 'completedCampaigns',
      header: () => (
        <p
          className="w-[170px] min-w-[120px] text-sm font-bold text-gray-600 dark:text-white"
          onClick={() =>
            sortingValue?.id == 'completedCampaigns' && setSorting([])
          }
        >
          Completed Campaigns
        </p>
      ),
      cell: info => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('points', {
      id: 'points',
      header: () => (
        <p
          className="w-[150px] min-w-[150px] text-sm font-bold text-gray-600 dark:text-white"
          onClick={() => sortingValue?.id == 'points' && setSorting([])}
        >
          Earned Points
        </p>
      ),
      cell: info => (
        <p className="w-[150px] min-w-[150px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue().toFixed(2) ?? 0}
        </p>
      ),
    }),
    columnHelper.accessor('country.name', {
      id: 'lastUserCountry',
      header: () => (
        <p className="w-[180px] min-w-[180px] text-sm font-bold text-gray-600 dark:text-white">
          <Select
            variant="auth"
            extra="w-full mb-3"
            id="country"
            onChange={e => {
              if (countriesList.some(option => option.id == e.target.value)) {
                setSelectedCountry(e.target.value);
              } else {
                setSelectedCountry(undefined);
              }
            }}
          >
            <option>Country</option>
            {Object.values(countriesList).map(option => {
              return (
                <option
                  key={option?.id}
                  value={option?.id}
                  selected={selectedCountry == option?.id}
                >
                  {option?.name}
                </option>
              );
            })}
          </Select>
        </p>
      ),
      cell: info => (
        <p className="w-[180px] min-w-[180px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">No Data</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('selectedCountry.name', {
      id: 'selectedCountry.name',
      header: () => (
        <p className="w-[220px] min-w-[220px] text-sm font-bold text-gray-600 dark:text-white">
          <Select
            variant="auth"
            extra="w-full mb-3"
            id="selected-user-country"
            onChange={e => {
              if (countriesList.some(option => option.id == e.target.value)) {
                setSelectedUserCountry(e.target.value);
              } else {
                setSelectedUserCountry(undefined);
              }
            }}
          >
            <option> Selected Country (Bio)</option>
            {Object.values(countriesList).map(option => {
              return (
                <option
                  key={option?.id}
                  value={option?.id}
                  selected={selectedUserCountry == option?.id}
                >
                  {option?.name}
                </option>
              );
            })}
          </Select>
        </p>
      ),
      cell: info => (
        <p className="w-[220px] min-w-[220px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">Not Selected</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('userLanguage', {
      id: 'userLanguage',
      header: () => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-gray-600 dark:text-white">
          <Select
            variant="auth"
            extra="w-full mb-3"
            id="gender"
            onChange={e => {
              if (languagesList.some(option => option.code == e.target.value)) {
                setSelectedLanguage(e.target.value);
              } else {
                setSelectedLanguage(undefined);
              }
            }}
          >
            <option>Language</option>
            {Object.values(languagesList).map(option => {
              return (
                <option
                  key={option?.code}
                  value={option?.code}
                  selected={selectedLanguage == option?.code}
                >
                  {option?.code}
                </option>
              );
            })}
          </Select>
        </p>
      ),
      cell: info => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('isPremium', {
      id: 'isPremium',
      header: () => (
        <p className="w-[280px] min-w-[280px] text-sm font-bold text-gray-600 dark:text-white">
          <Select
            variant="auth"
            extra="w-full mb-3"
            id="tg-premium-status"
            onChange={e => {
              if (
                booleanOptions.some(option => option.name == e.target.value)
              ) {
                setSelectedTgPremiumStatus(e.target.value);
              } else {
                setSelectedTgPremiumStatus(undefined);
              }
            }}
          >
            <option>TG Premium</option>
            {Object.values(booleanOptions).map(option => {
              return (
                <option
                  key={option?.id}
                  value={option?.name}
                  selected={selectedTgPremiumStatus == option?.name}
                >
                  {option?.name}
                </option>
              );
            })}
          </Select>
        </p>
      ),
      cell: info => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() ? (
            <span>Yes</span>
          ) : (
            <span className="text-gray-500">No</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('referralCount', {
      id: 'referralCount',
      header: () => (
        <p
          className="w-[50px] min-w-[50px] text-sm font-bold text-gray-600 dark:text-white"
          onClick={() => sortingValue?.id == 'referralCount' && setSorting([])}
        >
          Referrals
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('premiumSubscriptionPlanName', {
      id: 'premiumSubscriptionPlanName',
      header: () => (
        <p className="w-[250px] min-w-[250px] text-sm font-bold text-gray-600 dark:text-white">
          <Select
            variant="auth"
            extra="w-full mb-3"
            id="utm-source"
            onChange={e => {
              if (
                premiumPlansList.some(option => option.id == e.target.value)
              ) {
                setSelectedSubscriptionPlan(e.target.value);
              } else {
                setSelectedSubscriptionPlan(undefined);
              }
            }}
          >
            <option>Premium Subscription Plan</option>
            {Object.values(premiumPlansList).map(plan => (
              <option
                key={plan?.id}
                value={plan?.id}
                selected={selectedSubscriptionPlan == plan?.id}
              >
                {plan?.title}
              </option>
            ))}
          </Select>
        </p>
      ),
      cell: info => (
        <p className="w-[250px] min-w-[250px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">No Plan</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('everlastingDuckPremium', {
      id: 'everlastingDuckPremium',
      header: () => (
        <p className="w-fit min-w-[180px] text-sm font-bold text-gray-600 dark:text-white">
          Lifetime Duck Premium
        </p>
      ),
      cell: info => (
        <p className="w-fit pr-2 min-w-[180px] text-sm font-bold text-navy-700 dark:text-white">
          {
            <Switch
              onChange={e =>
                handleEverlastingDuckPremium(
                  e.target.checked,
                  info.cell.row.original.id
                )
              }
              defaultChecked={info.cell.getValue()}
            />
          }
        </p>
      ),
    }),
    columnHelper.accessor('customIncomePercent', {
      id: 'customIncomePercent',
      header: () => (
        <p className="w-fit min-w-[180px] text-sm font-bold text-gray-600 dark:text-white">
          Custom Passive Income %
        </p>
      ),
      cell: info => (
        <p className="w-fit pr-2 min-w-[180px] text-sm font-bold text-navy-700 dark:text-white">
          <InputField
            variant="auth"
            defaultValue={
              tableData.data.find(user => user.id == info.cell.row.original.id)
                ?.customIncomePercent || ''
            }
            className="mt-0 h-8 pl-1 rounded-md dark:text-[#000]"
            extra="w-full !border border-gray-300 rounded-md"
            placeholder="Enter custom %"
            id="customIncomePercent"
            type="number"
            onChange={e => {
              handleCustomIncomePercent(
                +e.target.value,
                info.cell.row.original.id
              );
            }}
          />
        </p>
      ),
    }),
    columnHelper.accessor('wallet.address', {
      id: 'wallet.address',
      header: () => (
        <p className="w-fit min-w-[120px] text-sm font-bold text-gray-600 dark:text-white">
          Wallet Address
        </p>
      ),
      cell: info => (
        <p className="w-fit pr-2 min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">Not Connected</span>
          )}
        </p>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Actions
        </p>
      ),
      cell: info => (
        <div className="flex gap-2 font-bold">
          <ButtonSM
            variant="primary"
            onClick={() =>
              handleAnaliticsModal(
                info.row.original.id,
                info.row.original.username
              )
            }
          >
            Analytics
          </ButtonSM>
        </div>
      ),
    }),
  ];

  const [data] = useDebounceValue(tableData?.data ?? [], 100);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const totalPages = Math.ceil(
    (tableData?.meta.totalItems ?? 0) / itemsPerPage
  );

  const handleItemsPerPageChange = (items: number) => {
    setItemsPerPage(items);
    setPage(0);
  };

  const createSortingNavigation = (header: Header<User, unknown>) => (
    <div className="flex items-center gap-2 mr-4">
      <div className="flex flex-col">
        <div
          onClick={e => {
            e.stopPropagation();
            if (
              sorting.some(value => value.id == header.id && value.desc == true)
            ) {
              setSorting([]);
            } else {
              setSorting([{ id: header.id, desc: true }]);
            }
          }}
          className="w-fit ml-4"
        >
          <BiCaretUp
            color={
              sortingValue && sortingValue.id == header.id
                ? !sortingValue.desc
                  ? '#727273'
                  : '#000'
                : '#727273'
            }
          />
        </div>
        <div
          onClick={e => {
            e.stopPropagation();
            if (
              sorting.some(
                value => value.id == header.id && value.desc == false
              )
            ) {
              setSorting([]);
            } else {
              setSorting([{ id: header.id, desc: false }]);
            }
          }}
          className="w-fit ml-4"
        >
          <BiCaretDown
            width={20}
            hanging={20}
            color={
              sortingValue && sortingValue.id == header.id
                ? sortingValue.desc
                  ? '#727273'
                  : '#000'
                : '#727273'
            }
          />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    utmService.getPaginatedUTMSources({ pageSize: 1000 }).then(
      resp =>
        resp?.data &&
        setUtmSources([
          ...resp.data,
          {
            id: 'Another traffic source',
            name: 'Another traffic source',
          },
        ])
    );
    getCountries().then(resp => resp && setCountriesList(resp));
    getLanguages().then(resp => resp && setLanguageList(resp));
    getPremiumPlans().then(
      resp =>
        resp?.data &&
        setPremiumPlansList([...resp.data, { title: 'No Plan', id: 'No Plan' }])
    );
  }, []);

  const handleReferralLinkSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const parsedId = rawValue.replace(/[^0-9]/g, '');
    setReferralLinkSearch(parsedId);
    setPage(0);
  };

  return (
    <Card extra={'w-full  max-h-[63rem] overflow-auto px-6 pb-6 grow'}>
      <header className="relative flex items-center gap-4 pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Users
        </div>

        <div className="flex gap-4 ml-auto">
          <input
            type="text"
            placeholder={'Search referral link'}
            className="block min-h-7 w-full grow rounded-full bg-lightPrimary px-4 text-base font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:h-full sm:w-fit max-w-96"
            value={referralLinkSearch}
            onChange={handleReferralLinkSearch}
          />

          <input
            type="text"
            placeholder={`Search by TG name or username`}
            className="block min-h-7 w-full grow rounded-full bg-lightPrimary px-4 text-base font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:h-full sm:w-fit max-w-96"
            value={search}
            onChange={e => {
              setSearch(e.target.value);
              setPage(0);
            }}
          />
        </div>
      </header>

      <div className="mt-8 grow overflow-x-auto">
        <table className="w-full">
          <thead className="sticky top-0 z-10">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    className={clsx(
                      'bg-white pb-2 pr-4 pt-4 text-start dark:bg-navy-800',
                      header.column.getCanSort() && 'cursor-pointer'
                    )}
                  >
                    <div className="flex items-center text-xs text-gray-200">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {(header.id == 'referralCount' ||
                        header.id == 'points' ||
                        header.id == 'completedTasks' ||
                        header.id == 'completedCampaigns' ||
                        header.id == 'age' ||
                        header.id == 'tgAccountCreationDate') &&
                        createSortingNavigation(header)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td className="absolute left-1/2 -translate-x-1/2" colSpan={8}>
                  <p className="flex justify-center py-10">
                    <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                  </p>
                </td>
              </tr>
            )}
            {!isLoading && (tableData?.data?.length ?? 0) <= 0 && (
              <tr>
                <td className="absolute left-1/2 -translate-x-1/2" colSpan={8}>
                  <p className="py-10 text-center font-bold uppercase">
                    No data
                  </p>
                </td>
              </tr>
            )}
            {!isLoading &&
              !!tableData &&
              tableData?.data?.length > 0 &&
              table.getRowModel().rows.map(row => (
                <tr
                  key={row.id}
                  className="[&_td]:odd:bg-gray-50 [&_td]:odd:dark:bg-navy-900"
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="min-w-[100px] border-white/0 py-2 pr-4 first:rounded-l-lg last:rounded-r-lg last:pr-0"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        totalItems={tableData?.meta.totalItems}
        onPageChange={setPage}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </Card>
  );
}

export default UsersTable;

const columnHelper = createColumnHelper<User>();
