import InputField from 'components/fields/InputField';
import { useFormContext, useWatch } from 'react-hook-form';

import { TUTMFormSchemeInfluencer } from '../../scheme/form';
import { transformUTMName } from 'utils/utm';
import { tasksService, TUserInfluencer } from 'views/services/tasksService';
import { useEffect, useState } from 'react';

type TFormProps = {
  isEdit: boolean;
};

export function UTMSourceForm({ isEdit }: TFormProps) {
  const form = useFormContext<TUTMFormSchemeInfluencer>();
  const { getInfluencer } = tasksService;
  const [influencer, setInfluencer] = useState<string>('');
  const [findedInfluencer, setFindedInfluencer] =
    useState<TUserInfluencer | null>(null);

  if (!form) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  const utmName = useWatch({ name: 'name' });

  const transformedName = transformUTMName(utmName);

  const handleInfluencerChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInfluencer(e.target.value);
    const data = await getInfluencer({
      username: e.target.value,
      includeZeroReferrals: true,
    });

    if (data && data.data) {
      const influencer = data.data;
      setFindedInfluencer(influencer);
      form.setValue('influencerId', influencer.id);
    } else {
      setFindedInfluencer(null);
      form.setValue('influencerId', '');
    }
  };

  useEffect(() => {
    if (isEdit) {
      setInfluencer(form.getValues('username'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <fieldset className="mb-[15px] flex flex-col">
          <InputField
            variant="auth"
            state={form.formState.errors.name ? 'error' : undefined}
            extra="w-full mb-1"
            label="Name"
            placeholder="Subscribe to our Telegram channel and get 10 points"
            id="name"
            {...form.register('name', {
              required: 'This field is required',
            })}
          />
          <span className="text-sm text-gray-500">
            ?startapp=utmSource-
            <span className="text-[#000] dark:text-white">
              {transformedName}
            </span>
          </span>
          {form.formState.errors.name && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.name.message}
            </span>
          )}
        </fieldset>

        <fieldset className="mb-[15px] flex flex-col">
          <InputField
            variant="auth"
            extra="w-full mb-1"
            label="Add Influencer"
            placeholder="Enter Influencer tg name"
            id="influencer"
            onChange={handleInfluencerChange}
            value={influencer}
            disabled={isEdit}
            readOnly={isEdit}
            required
          />
          {!!influencer && !isEdit && (
            <>
              {findedInfluencer ? (
                <p className="text-sm flex gap-1">
                  <span>Influencer: {findedInfluencer.username}</span>
                  <span>(Referrals: {findedInfluencer.referralCount})</span>
                </p>
              ) : (
                <span className="text-sm text-red-500 dark:text-red-400">
                  Influencer not found
                </span>
              )}
            </>
          )}
        </fieldset>
      </div>
    </>
  );
}
