import {
  TQuestion,
  TQuizItem,
  TStory,
  TStoryTask,
} from 'views/services/tasksService';

export enum TaskType {
  TELEGRAM = 'TELEGRAM',
  SNAPSHOT = 'SNAPSHOT',
  YOUTUBE = 'YOUTUBE',
  QUIZ = 'QUIZ',
  COMMON = 'COMMON',
  STORY = 'STORY',
  TRANSACTION = 'TRANSACTION',
  WALLET = 'WALLET',
  REFERRAL = 'REFERRAL',
  BIO = 'BIO',
}

export enum TaskCategoriesEnum {
  MAIN = 'MAIN',
  DAILY = 'DAILY',
  PARTNERS = 'PARTNERS',
}

export enum GendersEnum {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum TelegramTaskType {
  SUBSCRIBE = 'SUBSCRIBE',
}

export enum TwitterTaskType {
  SUBSCRIBE = 'SUBSCRIBE',
  LIKE = 'LIKE_POST',
  RETWEET_POST = 'RETWEET_POST',
}
export enum SnapshotTaskStatusesEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  REVIEW = 'REVIEW',
  REJECTED = 'REJECTED',
}

export enum TaskStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum TasksTab {
  PUSSY = 'Pussy',
  PARTNERS = 'Partners',
}

export enum EPlatforms {
  ios = 'ios',
  android = 'android',
}

export enum EAndOr {
  AND = 'and',
  OR = 'or',
}

export interface Task {
  id: string;
  type: TaskType;
  category: TaskCategoriesEnum;
  availableFromLevel: number;
  title: string;
  rewardPointsAmount: number;
  description: string;
  status: TaskStatus;
  redirectUrl: string;
  telegramResourceId?: number;
  secretWord?: string;
  startVideoActionLabel?: string;
  order: number;
  iconUrl?: string;
  inviteCount?: number;
  usersCompletedCount?: number;
  usersTotalCount?: number;
  targetGenders: GendersEnum[];
  targetLanguages?: string[] | null;
  languagesAndCountries: boolean;
  targetCountries?: { id: string; code: string; name: string }[] | null;
  targetUtmSources?: { id: string; code: string; name: string }[] | null;
  isTgPremiumOnly: boolean;
  isTgStandardOnly: boolean;
  isDuckPremiumOnly: boolean;
  isUsersWithWalletOnly: boolean;
  isUsersWithoutWalletOnly: boolean;
  targetPlatforms?: EPlatforms[];
  quizQuestions?: TQuestion[];
  stories?: TStory[];
  quiz?: TQuizItem;
  influencerTasks?: InfluencerTask[];
  transactionTask?: {
    transactionWalletAddress: string;
    transactionAmount: number;
  };
  transactionWalletAddress?: string;
  transactionAmount?: number;
  usersCompletedCountMax?: number;
}

export type InfluencerTask = {
  influencerId: string;
  redirectUrl: string;
  order?: number;
  referralCount?: number;
  influencerUsername?: string | undefined;
};

export type Campaign = {
  id: string;
  title: string;
  imageUrl: string;
  description: string;
  rewardAmount: string;
  isActive: boolean;
  isTgPremiumOnly: boolean;
  targetPlatforms?: EPlatforms[];
  isDuckPremiumOnly: boolean;
  approximateTime: string;
  endDate: string; // ISO string format
  daysLeft: number;
  tasks: CampaignTask[];
  targetGenders: GendersEnum[];
  targetMinAge: number | null;
  targetMaxAge: number | null;
  targetLanguages?: string[] | null;
  languagesAndCountries: boolean;
  targetCountries?: string[] | null;
  targetUtmSources?: string[] | null;
  usersCompletedCount: number;
  targetedUsers: number;
  withNotification: boolean;
  usersCompletedCountMax?: number;
  isUsersWithWalletOnly: boolean;
  isUsersWithoutWalletOnly: boolean;
};

export type CampaignTask = {
  id: string;
  type: TaskType;
  title: string;
  description: string;
  order: number;
  rewardPointsAmount: number;
  redirectUrl: string;
  taskStatus: TaskStatus;
  campaignId: string;
  quiz: TQuizItem;
  quizQuestions?: TQuestion[];
  createdAt: string;
  userTaskId?: string;
  status?: SnapshotTaskStatusesEnum;
  userId?: string;
  userReferrals?: number;
  inviteCount?: number;
  referrals?: number;
  secretWord?: string;
  startVideoActionLabel?: string;
  storyTask: TStoryTask;
  stories?: TStory[];
  targetGenders: GendersEnum[];
  targetLanguages?: string[] | null;
  languagesAndCountries: boolean;
  targetCountries?: { id: string; code: string; name: string }[] | null;
  iconUrl?: string;
  transactionTask?: {
    transactionWalletAddress: string;
    transactionAmount: number;
  };
  transactionWalletAddress?: string;
  transactionAmount?: number;
  isUsersWithWalletOnly: boolean;
  isUsersWithoutWalletOnly: boolean;
};
