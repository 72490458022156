import { Paginated } from 'types/paginated';
import { User, UsersStatistics } from '../types/typeUser';
import { $api } from './interceptor';
import { toast } from 'App';
import { TAnaliticsData } from 'views/types/analitics';

const usersApi = () => {
  const getUsersStatistic = async () => {
    const response = await $api.get<UsersStatistics>('api/users/statistic');
    if (response.status === 200) {
      return response.data;
    }
  };

  const upgradeUserIncomePercent = async (
    userId: string,
    customIncomePercent: number
  ) => {
    try {
      await $api.put(`api/users/upgrade/${userId}`, {
        customIncomePercent,
      });
      toast({
        title: 'User has been updated successfully',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (err) {
      console.log(err);
      toast({
        title: 'Error while updating user',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const upgradeUserDuckPremiumStatus = async (
    userId: string,
    everlastingDuckPremium: boolean
  ) => {
    try {
      await $api.put(`api/users/upgrade/${userId}`, {
        everlastingDuckPremium,
      });
      toast({
        title: 'User has been updated successfully',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    } catch (err) {
      console.log(err);
      toast({
        title: 'Error while updating user',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const getUsersList = async () => {
    const { data } = await $api.get<Paginated<User[]>>('api/users/list');
    return data.data;
  };

  const getPaginatedUsersList = async ({
    page = 0,
    pageSize = 10,
    searchValue,
    sortingValue,
    utmSource,
    tgPremiumStatus,
    gender,
    country,
    userCountry,
    subscriptionPlan,
    languageCode,
    referralLinkFilter,
  }: {
    page?: number;
    searchField?: string;
    pageSize?: number;
    searchValue?: string;
    sortingValue?: string;
    utmSource?: string;
    tgPremiumStatus?: string;
    gender?: string;
    country?: string;
    userCountry?: string;
    subscriptionPlan?: string;
    languageCode?: string;
    referralLinkFilter?: string;
  }): Promise<Paginated<User>> => {
    const search = searchValue;

    if (utmSource == 'Another traffic source') {
      utmSource = '$null';
    }

    const { data } = await $api.get<Paginated<User>>('api/users/list', {
      params: {
        page: page + 1,
        limit: pageSize,
        search,
        searchBy: ['telegram_name', 'username'],
        ...(tgPremiumStatus == 'YES' ? { 'filter.is_premium': 'true' } : {}),
        ...(tgPremiumStatus == 'NO'
          ? { 'filter.is_premium': '$not:true' }
          : {}),
        ...(sortingValue?.includes('age:')
          ? { 'filter.age': '$not:$null' }
          : {}),
        'filter.utm_source': utmSource,
        'filter.country_id': country,
        'filter.selected_country_id': userCountry,
        'filter.gender': gender == 'Not Selected' ? '$null' : gender,
        ...(subscriptionPlan !== 'No Plan'
          ? { 'filter.premium_subscription_id': subscriptionPlan }
          : {}),
        ...(subscriptionPlan === 'No Plan'
          ? { 'filter.is_duck_premium': false }
          : {}),
        'filter.language_code': languageCode,
        ...(referralLinkFilter
          ? { 'filter.telegram_user_id': referralLinkFilter }
          : {}),
        sortBy: sortingValue,
      },
    });

    return data;
  };

  const getUser = async (userID: number) => {
    try {
      const response = await $api.get<Paginated<User>>('api/users/list', {
        params: { page: 1, limit: 10, search: userID },
      });

      const user = response.data.data?.at(0);

      if (!user) {
        return;
      }
      return user;
    } catch (error) {
      console.log(error);
    }
  };

  const patchUser = async (userID: number, user: User) => {
    await $api.patch(`/users/${userID}`, { ...user });
  };

  const downloadCSV = async () => {
    const { data } = await $api.get('api/users/csv', {
      responseType: 'blob',
    });

    const virtualLink = window.document.createElement('a');
    virtualLink.href = window.URL.createObjectURL(data);
    virtualLink.download = `users-${new Date().toISOString()}.csv`;
    virtualLink.click();
    virtualLink.remove();
  };

  const getReferralsStats = async (id: string) => {
    const { data } = await $api.get<TAnaliticsData>(
      `/api/users/ref-stat/${id}`
    );
    return data;
  };

  return {
    getUsersStatistic,
    getReferralsStats,
    getUsersList,
    getUser,
    patchUser,
    getPaginatedUsersList,
    downloadCSV,
    upgradeUserIncomePercent,
    upgradeUserDuckPremiumStatus,
  };
};

export const usersService = usersApi();
