export const booleanOptions = [
  {
    id: 'true',
    name: 'YES',
  },
  {
    id: 'false',
    name: 'NO',
  },
];

export const genderOptions = [
  {
    id: 'Male',
    name: 'Male',
  },
  {
    id: 'Female',
    name: 'Female',
  },
  {
    id: 'Not Selected',
    name: 'Not Selected',
  },
];
