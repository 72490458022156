import InputField from 'components/fields/InputField';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { TagFormScheme } from '../../scheme/form';
import { useEffect, useState } from 'react';
import { utmService } from 'views/services/utmService';

export function UTMSourceForm() {
  const form = useFormContext<TagFormScheme>();
  const [utmList, setUtmList] = useState<{ name: string; id: string }[]>([]);
  const [isUtmLoading, setIsUtmLoading] = useState(false);

  if (!form) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  useEffect(() => {
    setIsUtmLoading(true);
    utmService
      .getAllUtmLinks()
      .then(data => {
        setUtmList(data);
      })
      .finally(() => {
        setIsUtmLoading(false);
      });
  }, []);

  const utmSourceIds: string[] = useWatch({
    name: 'utmSourceIds',
  });

  const handleCheckboxChange = (utmId: string, isChecked: boolean) => {
    const updatedUtmSources = isChecked
      ? [...utmSourceIds, utmId]
      : utmSourceIds.filter(id => id !== utmId);

    form.setValue('utmSourceIds', updatedUtmSources, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <>
      <div className="flex flex-col">
        <fieldset className="mb-[15px] flex flex-col">
          <InputField
            variant="auth"
            state={form.formState.errors.name ? 'error' : undefined}
            extra="w-full mb-1"
            label="Name"
            placeholder="Enter tag name"
            id="name"
            {...form.register('name', {
              required: 'This field is required',
            })}
          />

          {form.formState.errors.name && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.name.message}
            </span>
          )}
        </fieldset>

        <fieldset className="mb-[15px] flex flex-col">
          <div className="flex items-center mb-2 ">
            <label className="text-sm font-medium text-navy-700 dark:text-white">
              Select UTM Links
            </label>
          </div>

          <div className="flex flex-col max-h-[200px] overflow-y-auto gap-2">
            {isUtmLoading && <span>Loading...</span>}
            {!isUtmLoading &&
              utmList.map(utm => (
                <label
                  key={utm.id}
                  className="flex items-center cursor-pointer"
                >
                  <Controller
                    name="utmSourceIds"
                    control={form.control}
                    rules={{
                      validate: value =>
                        value.length > 0 ||
                        'At least one UTM link must be selected',
                    }}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        id={`utm-${utm.id}`}
                        checked={utmSourceIds.includes(utm.id)}
                        onChange={e =>
                          handleCheckboxChange(utm.id, e.target.checked)
                        }
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                  <span className="ml-2">{utm.name}</span>
                </label>
              ))}
          </div>

          {form.formState.errors.name && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.name.message}
            </span>
          )}

          {form.formState.errors.utmSourceIds && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.utmSourceIds.message}
            </span>
          )}
        </fieldset>
      </div>
    </>
  );
}
