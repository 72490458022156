import { $api } from './interceptor';

export interface Country {
  code: string;
}

const getPremiumPlans = async () => {
  try {
    const { data } = await $api.get('/api/premium-plans/list');
    return data;
  } catch (e) {
    console.log(e);
  }
};

export default getPremiumPlans;
