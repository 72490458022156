import { getDateDifference } from 'utils/date';
import { ExtraAnaliticsFields } from './ExtraAnaliticsFields';
import { UsersStatistics } from 'views/types/typeUser';
import { TAppStatisticsMapped } from 'views/types/analitics';
import {
  renderCountriesData,
  renderDevicesData,
  renderGenderData,
  renderLanguagesData,
  renderPremiumPlansData,
  renderSelectedCountriesData,
  renderUsersFilledBioData,
  renderUtmSourcesData,
  renderWalletsData,
} from './helpers';

type TAppStatisticsProps = {
  data: UsersStatistics;
};

export const AppStasistics = ({ data }: TAppStatisticsProps) => {
  const usersTotal = data.totalCount;

  const calcPercent = (count: number) => {
    return Math.round((count / usersTotal) * 100);
  };

  const mapObject = (obj: Record<string, number>) => {
    return Object.entries(obj).map(([name, count]) => ({
      name,
      usersCount: count,
      usersPercent: calcPercent(count),
    }));
  };

  const mappedData: TAppStatisticsMapped = {
    totalUsersCount: usersTotal,
    genderData: {
      femaleCount: data.genders.female,
      femalePercent: calcPercent(data.genders.female),
      maleCount: data.genders.male,
      malePercent: calcPercent(data.genders.male),
      otherGenderCount: usersTotal - data.genders.female - data.genders.male,
      otherGenderPercent:
        100 - calcPercent(data.genders.female) - calcPercent(data.genders.male),
    },
    avgTelegramUserAge: data.avgTelegramUserAge,
    devicesData: {
      androidCount: data.platforms.android,
      iosCount: data.platforms.ios,
      androidPercent: calcPercent(data.platforms.android),
      iosPercent: calcPercent(data.platforms.ios),
      otherPlatformsCount:
        usersTotal - data.platforms.android - data.platforms.ios,
      otherPlatformsPercent:
        100 -
        calcPercent(data.platforms.android) -
        calcPercent(data.platforms.ios),
    },
    countriesData: mapObject(data.countries),
    languagesData: mapObject(data.languages),
    selectedCountriesData: mapObject(data.selectedCountries),
    usersFilledBioData: {
      usersCount: data.totalFilledBio,
      usersPercent: calcPercent(data.totalFilledBio),
    },
    duckPremiumData: {
      usersCount: data.duckPremiumCount,
      usersPercent: calcPercent(data.duckPremiumCount),
    },
    tgPremiumData: {
      usersCount: data.tgPremiumCount,
      usersPercent: calcPercent(data.tgPremiumCount),
    },
    utmSourcesData: mapObject(data.utmSources),
    walletsData: {
      usersCount: data.withWalletCount,
      usersPercent: calcPercent(data.withWalletCount),
    },
  };

  return (
    <>
      {data && data.totalCount > 0 ? (
        <div className="w-full flex flex-col gap-8 max-h-[80svh] overflow-y-auto  mt-4">
          <div>
            <h2 className="text-2xl font-semibold mb-2 text-3xl border-b border-gray-200 dark:!border-white/10 pb-2">
              App Statistics:
            </h2>
            <div className="flex gap-8">
              <div className="flex gap-2">
                <h3 className="min-w-[100px] font-bold">Users Total:</h3>
                <p>{data.totalCount}</p>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="flex gap-2">
                <h3 className="min-w-[100px] font-bold">Users AVG TG Age:</h3>
                <p>{getDateDifference(data.avgTelegramUserAge)}</p>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-200 dark:!border-white/10 -mt-4" />

          {renderDevicesData(mappedData.devicesData)}
          {renderGenderData(mappedData.genderData)}
          {renderPremiumPlansData(
            mappedData.duckPremiumData,
            mappedData.tgPremiumData,
            usersTotal,
            usersTotal
          )}
          {renderUtmSourcesData(mappedData.utmSourcesData, usersTotal)}
          {renderWalletsData(mappedData.walletsData, usersTotal, usersTotal)}
          {renderCountriesData(mappedData.countriesData)}
          {renderSelectedCountriesData(
            mappedData.selectedCountriesData,
            usersTotal
          )}
          {renderLanguagesData(mappedData.languagesData)}
          {renderUsersFilledBioData(mappedData.usersFilledBioData, usersTotal)}

          <ExtraAnaliticsFields
            resourceId=""
            totalUsers={usersTotal}
            type="empty"
          />
        </div>
      ) : (
        <p>No data</p>
      )}
    </>
  );
};
