import * as Dialog from '@radix-ui/react-dialog';
import { MdClose } from 'react-icons/md';

import clsx from 'clsx';
import { TAnaliticsData } from 'views/types/analitics';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import { useEffect, useState } from 'react';
import { tasksService } from 'views/services/tasksService';
import { campaignService } from 'views/services/campaignService';
import { utmService } from 'views/services/utmService';
import { usersService } from 'views/services/usersService';
import { NonAppAnalitics } from './NonAppAnalitics';
import { AppStasistics } from './AppStatistic';

export const AnaliticsDialog = () => {
  const {
    id,
    type,
    loading,
    appStatistics,
    tasksFilterBy,
    title,
    setId,
    setLoading,
    setTitle,
    setAppStatistics,
    setTasks,
    setTasksLoading,
    setSelectedTaskId,
    setType,
  } = useAnaliticsStore();
  const [data, setData] = useState<TAnaliticsData | null>(null);

  useEffect(() => {
    if (id) {
      if (type === EAnaliticDataTypeEnum.task) {
        setLoading(true);
        tasksService
          .getTaskStats(id)
          .then(setData)
          .finally(() => setLoading(false));
      }

      if (type === EAnaliticDataTypeEnum.campaign) {
        setLoading(true);
        campaignService
          .getCampaignAnalytics(id)
          .then(setData)
          .finally(() => setLoading(false));
      }

      if (type === EAnaliticDataTypeEnum.utm) {
        setLoading(true);
        utmService
          .getUTMStats(id)
          .then(setData)
          .finally(() => setLoading(false));
      }

      if (type === EAnaliticDataTypeEnum.referral) {
        setLoading(true);
        usersService
          .getReferralsStats(id)
          .then(setData)
          .finally(() => setLoading(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (type === EAnaliticDataTypeEnum.utm) {
      setTasksLoading(true);
      tasksService
        .getAllTasks(tasksFilterBy)
        .then(setTasks)
        .finally(() => {
          setTasksLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, tasksFilterBy]);

  const handleClose = () => {
    setData(null);
    setId(null);
    setTitle('');
    setAppStatistics(null);
    setType(null);
    setSelectedTaskId(null);
    setType(null);
  };

  const getAnaliticsType = (type: EAnaliticDataTypeEnum) => {
    switch (type) {
      case EAnaliticDataTypeEnum.task:
        return 'task';
      case EAnaliticDataTypeEnum.campaign:
        return 'campaign';
      case EAnaliticDataTypeEnum.utm:
        return 'utmSource';
      case EAnaliticDataTypeEnum.referral:
        return 'empty';
      default:
        return 'empty';
    }
  };

  const isDataEmpty =
    type !== EAnaliticDataTypeEnum.app &&
    data &&
    (data.completedUsersCount < 1 || data.totalUsersCount < 1);

  return (
    <>
      <Dialog.Root open={!!id} onOpenChange={handleClose} modal>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content
            onInteractOutside={e => {
              if ((e.target as HTMLElement).closest('.chakra-toast')) {
                e.preventDefault();
              }
            }}
            className={clsx(
              'data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[800px]',
              'translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px]',
              'shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]',
              'focus:outline-none dark:bg-gray-800 dark:text-white',
              'flex'
            )}
          >
            {loading && <p>Loading...</p>}
            {((!data && !loading && !appStatistics) || isDataEmpty) && (
              <p>No data</p>
            )}
            {type !== EAnaliticDataTypeEnum.app && data && !isDataEmpty ? (
              <NonAppAnalitics
                data={data}
                id={id}
                type={type}
                extraAnaliticsType={getAnaliticsType(type)}
                title={title}
              />
            ) : (
              <>
                {!loading && appStatistics && (
                  <AppStasistics data={appStatistics} />
                )}
              </>
            )}
            <Dialog.Close asChild>
              <button
                id="task-modal-close-btn"
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
