import { createStandaloneToast } from '@chakra-ui/toast';
import { $api } from './interceptor';
import { AxiosError } from 'axios';
import { transformUTMName } from 'utils/utm';
import { TAnaliticsData } from 'views/types/analitics';

export const { toast } = createStandaloneToast();

const utmSourceApi = () => {
  const getPaginatedUTMSources = async ({
    page = 0,
    pageSize = 10,
    searchValue,
    isInfluencersSources,
  }: {
    page?: number;
    pageSize?: number;
    searchValue?: string;
    isInfluencersSources?: boolean;
  }) => {
    const { data } = await $api.get('/api/utm-sources', {
      params: {
        page: page + 1,
        limit: pageSize,
        search: searchValue,
        ...(typeof isInfluencersSources == 'boolean'
          ? {
              'filter.influencer_id': isInfluencersSources
                ? '$not:$null'
                : '$null',
            }
          : {}),
      },
    });

    return data;
  };

  const getAllUtmLinks = async () => {
    const pageSize = 100;
    let allUtms: unknown[] = [];
    let currentPage = 0;
    let totalPages = 1;

    try {
      while (currentPage < totalPages) {
        const data = await getPaginatedUTMSources({
          page: currentPage,
          pageSize,
        });
        allUtms = [...allUtms, ...data.data];
        totalPages = data.meta.totalPages;
        currentPage++;
      }
    } catch (e) {
      console.log(e);
    }

    return allUtms as { name: string; id: string }[];
  };

  const getUTMStats = async (id: string) => {
    const { data } = await $api.get<TAnaliticsData>(
      `/api/utm-sources/stat/${id}`
    );
    return data;
  };

  const createUTMSource = async (data: {
    name: string;
    influencerId?: string;
  }) => {
    try {
      await $api.post('/api/utm-sources', {
        name: transformUTMName(data.name),
        ...(data?.influencerId
          ? {
              influencerId: data.influencerId,
            }
          : {}),
      });
      toast({
        title: 'UTM Source created',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: error.response?.data.message || 'Error creating UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error creating UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const editUTMSource = async (id: string, data: { name: string }) => {
    try {
      await $api.patch(`/api/utm-sources/${id}`, {
        name: transformUTMName(data.name),
      });
      toast({
        title: 'UTM Source updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: error.response?.data.message || 'Error updating UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error updating UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const deleteUTMSource = async (id: string) => {
    try {
      await $api.delete(`/api/utm-sources/${id}`);
      toast({
        title: 'UTM Source deleted',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: error.response?.data.message || 'Error deleting UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error deleting UTM Source',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  return {
    getPaginatedUTMSources,
    getUTMStats,
    createUTMSource,
    editUTMSource,
    deleteUTMSource,
    getAllUtmLinks,
  };
};

export const utmService = utmSourceApi();
