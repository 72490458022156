import { Task, TaskCategoriesEnum } from 'views/types/tasks';
import { UsersStatistics } from 'views/types/typeUser';
import { create, useStore } from 'zustand';

export enum EAnaliticDataTypeEnum {
  task = 'task',
  campaign = 'campaign',
  utm = 'utm',
  referral = 'referral',
  app = 'app',
}

interface AnaliticsStore {
  type: EAnaliticDataTypeEnum;
  id: string | null;
  loading: boolean;
  setId: (id: string) => void;
  setLoading: (loading: boolean) => void;
  title: string;
  setTitle: (title: string) => void;
  setType: (type: EAnaliticDataTypeEnum) => void;
  setAnalitic: (type: EAnaliticDataTypeEnum, id: string, title: string) => void;
  appStatistics: UsersStatistics | null;
  setAppStatistics: (data: UsersStatistics) => void;
  tasks: Task[] | null;
  tasksLoading: boolean;
  selectedTaskId: string | null;
  tasksFilterBy: TaskCategoriesEnum | null;
  setTasks: (tasks: Task[]) => void;
  setTasksLoading: (loading: boolean) => void;
  setSelectedTaskId: (id: string) => void;
  setTasksFilterBy: (category: TaskCategoriesEnum) => void;
}

export const analitics = create<AnaliticsStore>(set => ({
  type: EAnaliticDataTypeEnum.task,
  id: null,
  loading: false,
  setId: id => set({ id }),
  setLoading: loading => set({ loading }),
  title: '',
  setTitle: title => set({ title }),
  setType: type => set({ type }),
  setAnalitic: (type, id, title) => set({ type, id, title }),
  appStatistics: null,
  setAppStatistics: data => set({ appStatistics: data }),
  tasks: null,
  tasksLoading: false,
  selectedTaskId: null,
  tasksFilterBy: null,
  setTasks: tasks => set({ tasks }),
  setTasksLoading: loading => set({ tasksLoading: loading }),
  setSelectedTaskId: id => set({ selectedTaskId: id }),
  setTasksFilterBy: category => set({ tasksFilterBy: category }),
}));

export const useAnaliticsStore = () => useStore(analitics);
