import * as Dialog from '@radix-ui/react-dialog';
import { ReactNode, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { tasksService, toast } from 'views/services/tasksService';
import {
  CampaignTask,
  EAndOr,
  EPlatforms,
  Task,
  TaskStatus,
  TaskType,
  TelegramTaskType,
} from 'views/types/tasks';
import { TaskFormScheme } from '../../scheme/taksForm';
import { TaskForm } from './Form';
import { MessForm } from './MessageForm';
import { campaignService } from 'views/services/campaignService';
import clsx from 'clsx';

interface TaskDialogProps {
  task?: Task | CampaignTask;
  children: ReactNode;
  onOpenChange: (isOpen: boolean) => void;
  onSuccess?: () => void;
  formType?: 'task' | 'message';
  campaignId?: string;
  hasQuiz?: boolean;
  hasStory?: boolean;
  isFormOpen?: boolean;
  isLoadingImage?: boolean;
  setIsLoadingImage?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFormOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TaskDialog = ({
  task,
  children,
  onOpenChange,
  onSuccess,
  formType,
  campaignId,
  hasQuiz,
  hasStory,
  isFormOpen,
  isLoadingImage,
  setIsLoadingImage,
}: TaskDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isEdit = !!task;

  const form = useForm<TaskFormScheme>({
    values: {
      task: {
        title: isEdit ? task?.title : '',
        type: isEdit ? task?.type : undefined,
        category: isEdit && 'category' in task ? task?.category : undefined,
        description: isEdit ? task?.description : '',
        order: isEdit && 'order' in task ? task?.order?.toString() : '',
        rewardPointsAmount: isEdit ? task?.rewardPointsAmount?.toString() : '',
        usersCompletedCountMax:
          isEdit && 'usersCompletedCountMax' in task
            ? task?.usersCompletedCountMax
            : undefined,
        redirectUrl: isEdit ? task?.redirectUrl : 'https://www.google.co.uk/',
        isActive: isEdit ? task?.status === TaskStatus.ACTIVE : true,
        iconUrl: isEdit && 'iconUrl' in task ? task?.iconUrl : '',
        targetMinAge:
          isEdit && 'targetMinAge' in task
            ? (task.targetMinAge as number)
            : null,
        targetMaxAge:
          isEdit && 'targetMaxAge' in task
            ? (task.targetMaxAge as number)
            : null,
        targetGenders: isEdit ? task?.targetGenders : [],
        targetCountries: isEdit
          ? Object.fromEntries(
              task?.targetCountries.map(item => [item?.id || '', true])
            )
          : {},
        targetUtmSourceIds:
          isEdit && 'targetUtmSources' in task
            ? Object.fromEntries(
                task?.targetUtmSources.map(item => [item?.id || '', true])
              )
            : {},
        languagesAndCountries:
          isEdit && 'languagesAndCountries' in task
            ? task.languagesAndCountries
              ? EAndOr.AND
              : EAndOr.OR
            : EAndOr.AND,
        targetLanguages: isEdit
          ? Object.fromEntries(
              task?.targetLanguages.map(item => [item || '', true])
            )
          : {},
        isDuckPremiumOnly:
          isEdit && 'isDuckPremiumOnly' in task
            ? task?.isDuckPremiumOnly
            : false,
        isTgPremiumOnly:
          isEdit && 'isTgPremiumOnly' in task ? task?.isTgPremiumOnly : false,
        isTgStandardOnly:
          isEdit && 'isTgStandardOnly' in task ? task?.isTgStandardOnly : false,
        onlyForIos:
          isEdit && 'targetPlatforms' in task
            ? task?.targetPlatforms.includes(EPlatforms.ios)
            : false,
        isUsersWithWalletOnly:
          isEdit && 'isUsersWithWalletOnly' in task
            ? task?.isUsersWithWalletOnly
            : false,
        isUsersWithoutWalletOnly: isEdit
          ? task?.isUsersWithoutWalletOnly
          : false,
      },
      telegramTask: {
        resourceId:
          isEdit && 'telegramResourceId' in task
            ? task?.telegramResourceId?.toString()
            : '',
        taskType: TelegramTaskType.SUBSCRIBE,
      },
      referralTask: {
        inviteCount: isEdit ? task?.inviteCount?.toString() : '',
      },
      quiz: isEdit
        ? task?.quiz
          ? {
              ...task.quiz,
              questions:
                task?.quizQuestions.map(question => ({
                  ...question,
                  question: question.text,
                })) || [],
            }
          : {
              approximateTime: 1,
              questions: [
                {
                  question: '',
                  answers: [
                    { text: '', correct: false },
                    { text: '', correct: false },
                  ],
                  description: '',
                },
              ],
            }
        : {
            approximateTime: 1,
            questions: [
              {
                question: '',
                answers: [
                  { text: '', correct: false },
                  { text: '', correct: false },
                ],
                description: '',
              },
            ],
          },
      youtubeTask: {
        secretWord: isEdit ? task?.secretWord?.toString() : '',
        startVideoActionLabel: isEdit ? task?.startVideoActionLabel || '' : '',
      },
      transactionTask: {
        transactionWalletAddress: task?.transactionWalletAddress || '',
        transactionAmount: +task?.transactionAmount || 0,
      },
      transactionWalletAddress: task?.transactionWalletAddress || '',
      transactionAmount: +task?.transactionAmount || 0,
      influencerTasks:
        task && 'influencerTasks' in task ? task?.influencerTasks : undefined,
      stories: task?.stories,
      storyTask: {
        story:
          isEdit && 'storyTask' in task
            ? task.stories
            : [{ imageUrl: '', order: 0 }],
      },
    },
  });

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    onOpenChange(isOpen);

    if (!isOpen) {
      form.reset();
    }
  };

  const handleSubmit = async (data: TaskFormScheme) => {
    try {
      if (formType === 'message') {
        const response = await tasksService.createMessage({
          message: data.task.message,
          images: data.task.images,
        });
        if (!response) return;
      } else {
        if (isEdit) {
          await tasksService.patchTask(task.id, data, campaignId);
          handleOpenChange(false);
        } else {
          if (data.task?.type == TaskType.QUIZ && hasQuiz) {
            toast({
              title: 'Error',
              description: 'You can create only one quiz for campaign',
              status: 'error',
              isClosable: true,
              position: 'top-right',
            });
          } else if (!!data.storyTask?.story[0]?.imageUrl?.length && hasStory) {
            toast({
              title: 'Error',
              description: 'You can create only one story for campaign',
              status: 'error',
              isClosable: true,
              position: 'top-right',
            });
          } else {
            await tasksService.createTaskSubmit(data, campaignId);
            handleOpenChange(false);
          }
        }
      }

      if (campaignId) {
        campaignService.getCampaigns();
      }
      onSuccess?.();
      form.reset();
      handleOpenChange(false);
    } catch (error) {
      console.warn('Error saving task', error);
    }
  };

  return (
    <>
      <Dialog.Root
        open={isOpen || (!!campaignId && isFormOpen)}
        onOpenChange={handleOpenChange}
        modal
      >
        <Dialog.Trigger asChild>{children}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content
            onInteractOutside={e => {
              if ((e.target as HTMLElement).closest('.chakra-toast')) {
                e.preventDefault();
              }
            }}
            className={clsx(
              'data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[800px]',
              'translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px]',
              'shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]',
              'focus:outline-none dark:bg-gray-800 dark:text-white',
              'flex'
            )}
          >
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="flex flex-col max-h-full w-full grow"
            >
              <Dialog.Title className="text-mauve12 mb-10 text-[17px] font-medium">
                {isEdit
                  ? 'Edit Task'
                  : formType === 'task'
                    ? 'Create Task'
                    : 'Create Message'}
              </Dialog.Title>
              <div className="grow overflow-y-auto overflow-x-hidden">
                <FormProvider {...form}>
                  {formType === 'message' ? (
                    <MessForm />
                  ) : (
                    <TaskForm
                      isEdit={isEdit}
                      isCampaign={!!campaignId}
                      isLoadingImage={isLoadingImage}
                      setIsLoadingImage={setIsLoadingImage}
                    />
                  )}
                </FormProvider>
              </div>
              <div className="mt-[10px] flex flex-col">
                {form.formState.errors.root && (
                  <span className="text-sm capitalize text-red-500 dark:text-red-400">
                    {form.formState.errors.root.message}
                  </span>
                )}
                <button
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled={
                    form.formState.isSubmitting ||
                    !form.formState.isDirty ||
                    isLoadingImage
                  }
                >
                  {form.formState.isSubmitting ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </form>
            <Dialog.Close asChild>
              <button
                id="task-modal-close-btn"
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
