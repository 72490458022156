import dayjs from 'dayjs';

export const formatDate = (date: string) => {
  return dayjs(date).format('DD.MM.YYYY HH:mm');
};

export const formatDateToMonthAndYear = (date: string) => {
  return dayjs(date).format('MM/YYYY');
};

export const getDateDifference = (date: string) => {
  const now = new Date();
  const then = new Date(date);
  const diff = now.getTime() - then.getTime();
  const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
  const years = Math.floor(months / 12);
  const finalMonths = months - years * 12;

  return `${years} y. ${finalMonths} m.`;
};

export const convertToTimestamp = (dateString: string): number => {
  return new Date(dateString).getTime();
};
