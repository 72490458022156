import { formatNumber } from 'utils/formatNumber';
import {
  TCountryData,
  TDevicesData,
  TGenderData,
  TLanguageData,
  TPremiumPlanData,
  TUsersFilledBioData,
  TUtmSourceData,
  TWalletsData,
} from 'views/types/analitics';
import { UserCountAndPercent } from './UserCountAndPercent';
import { FieldWithChart } from './FieldWithChart';

const calcPercents = (totalUsers: number, altPercent: number) => {
  return !totalUsers ? 0 : 100 - altPercent;
};

export const renderGenderData = (genderData: TGenderData) => (
  <div className="flex gap-20 w-full">
    <h3 className="min-w-[120px] max-w-[120px] font-bold">Gender:</h3>

    <div className="flex flex-col gap-1">
      <p>
        <span className="mr-4 font-bold">Male:</span>
        {formatNumber(genderData.maleCount)} (
        {formatNumber(genderData.malePercent)}%)
      </p>
      <p>
        <span className="mr-4 font-bold">Female:</span>
        {formatNumber(genderData.femaleCount)} (
        {formatNumber(genderData.femalePercent)}%)
      </p>
      <p>
        <span className="mr-4 font-bold">Other:</span>
        {formatNumber(genderData.otherGenderCount)} (
        {formatNumber(genderData.otherGenderPercent)}%)
      </p>
    </div>
  </div>
);

export const renderCountriesData = (countriesData: TCountryData[]) => {
  const sortedCountriesData = countriesData.sort(
    (a, b) => b.usersCount - a.usersCount
  );
  return (
    <div className="flex gap-20 w-full border-b border-gray-200 dark:!border-white/10">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">Countries:</h3>

      {countriesData.length > 0 && (
        <FieldWithChart data={sortedCountriesData} />
      )}
    </div>
  );
};

export const renderSelectedCountriesData = (
  selectedCountriesData: TCountryData[],
  totalCompletedUsers: number
) => {
  const nonSelectedCountriesUsers =
    totalCompletedUsers -
    selectedCountriesData.reduce((acc, country) => acc + country.usersCount, 0);
  const nonSelectedCountriesUsersPercent =
    100 -
    selectedCountriesData.reduce(
      (acc, country) => acc + country.usersPercent,
      0
    );
  const sortedSelectedCountriesData = selectedCountriesData.sort(
    (a, b) => b.usersCount - a.usersCount
  );

  if (nonSelectedCountriesUsers > 0) {
    sortedSelectedCountriesData.push({
      name: 'Without selected country',
      usersCount: nonSelectedCountriesUsers,
      usersPercent: nonSelectedCountriesUsersPercent,
    });
  }

  return (
    <div className="flex pb-8 gap-20 w-full border-b border-gray-200 dark:!border-white/10">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">
        Selected Country (Bio):
      </h3>

      {selectedCountriesData.length > 0 && (
        <FieldWithChart data={sortedSelectedCountriesData} />
      )}
    </div>
  );
};

export const renderLanguagesData = (languagesData: TLanguageData[]) => {
  const sortedLanguagesData = languagesData.sort(
    (a, b) => b.usersCount - a.usersCount
  );
  return (
    <div className="flex gap-20 w-full">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">Languages:</h3>

      {languagesData.length > 0 && (
        <FieldWithChart data={sortedLanguagesData} />
      )}
    </div>
  );
};

export const renderPremiumPlansData = (
  duckPremiumData: TPremiumPlanData,
  tgPremiumData: TPremiumPlanData,
  completedUsersCount: number,
  totalUsersCount: number
) => {
  const totalUsers =
    completedUsersCount >= 0 ? completedUsersCount : totalUsersCount;

  const withoutDuckCount = totalUsers - duckPremiumData.usersCount;
  const withoutDuckPercent = calcPercents(
    totalUsers,
    duckPremiumData.usersPercent
  );

  const withoutTgCount = totalUsers - tgPremiumData.usersCount;
  const withoutTgPercent = calcPercents(totalUsers, tgPremiumData.usersPercent);

  return (
    <div>
      <div className="flex gap-20 w-full">
        <h3 className="min-w-[120px] max-w-[120px] font-bold">Premium:</h3>
        <div className="grid gap-4 grid-cols-2">
          <div className="w-[200px]">
            <h4 className="font-bold">Duck premium</h4>
            <UserCountAndPercent
              count={duckPremiumData.usersCount}
              percent={duckPremiumData.usersPercent}
            />
          </div>

          <div className="w-[200px]">
            <h4 className="font-bold">TG premium</h4>
            <UserCountAndPercent
              count={tgPremiumData.usersCount}
              percent={tgPremiumData.usersPercent}
            />
          </div>

          <div className="w-[200px]">
            <h4 className="font-bold">Without Duck premium</h4>
            <UserCountAndPercent
              count={withoutDuckCount}
              percent={withoutDuckPercent}
            />
          </div>

          <div className="w-[200px]">
            <h4 className="font-bold">Without TG premium</h4>
            <UserCountAndPercent
              count={withoutTgCount}
              percent={withoutTgPercent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderUtmSourcesData = (
  utmSourcesData: TUtmSourceData[],
  completedUsersCount: number
) => {
  const totalUsersCount = utmSourcesData.reduce(
    (acc, source) => acc + source.usersCount,
    0
  );
  const totalUsersPercent = utmSourcesData.reduce(
    (acc, source) => acc + source.usersPercent,
    0
  );
  const otherUsersCount = completedUsersCount
    ? completedUsersCount - totalUsersCount
    : 0;
  const otherUsersPercent = completedUsersCount ? 100 - totalUsersPercent : 0;

  return (
    <div className="flex gap-20 w-full">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">UTM Sources:</h3>

      <div className="grid gap-4 grid-cols-2">
        {utmSourcesData.map((source, index) => (
          <div key={index} className="w-[200px]">
            <p className="font-bold">{source.name}</p>
            <UserCountAndPercent
              count={source.usersCount}
              percent={source.usersPercent}
            />
          </div>
        ))}
        <div className="w-[200px]">
          <p className="font-bold">Another traffic:</p>
          <UserCountAndPercent
            count={otherUsersCount}
            percent={otherUsersPercent}
          />
        </div>
      </div>
    </div>
  );
};

export const renderWalletsData = (
  walletsData: TWalletsData,
  completedUsersCount: number,
  totalUsersCount: number
) => {
  const totalUsers =
    completedUsersCount >= 0 ? completedUsersCount : totalUsersCount;
  const withoutWalletsCount = totalUsers - walletsData.usersCount;
  const withoutWalletsPercent = calcPercents(
    totalUsers,
    walletsData.usersPercent
  );

  return (
    <div className="flex gap-20 w-full">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">Wallets:</h3>
      <div className="grid gap-4 grid-cols-2">
        <div className="flex flex-col gap-1 w-[200px]">
          <p className="font-bold">With wallet:</p>
          <UserCountAndPercent
            count={walletsData.usersCount}
            percent={walletsData.usersPercent}
          />
        </div>

        <div className="flex flex-col gap-1 w-[200px]">
          <p className="font-bold">Without wallet:</p>
          <UserCountAndPercent
            count={withoutWalletsCount}
            percent={withoutWalletsPercent}
          />
        </div>
      </div>
    </div>
  );
};

export const renderDevicesData = (devicesData: TDevicesData) => {
  return (
    <div className="flex gap-20 w-full">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">System:</h3>

      <div className="grid gap-4 grid-cols-2">
        <div className="w-[200px]">
          <h4 className="font-bold">iOS</h4>
          <UserCountAndPercent
            count={devicesData.iosCount}
            percent={devicesData.iosPercent}
          />
        </div>

        <div className="w-[200px]">
          <h4 className="font-bold">Android</h4>
          <UserCountAndPercent
            count={devicesData.androidCount}
            percent={devicesData.androidPercent}
          />
        </div>

        <div className="w-[200px]">
          <h4 className="font-bold">Other platforms</h4>
          <UserCountAndPercent
            count={devicesData.otherPlatformsCount}
            percent={devicesData.otherPlatformsPercent}
          />
        </div>
      </div>
    </div>
  );
};

export const renderUsersFilledBioData = (
  usersFilledBioData: TUsersFilledBioData,
  completedUsersCount: number
) => {
  const totalUsers =
    completedUsersCount >= 0
      ? completedUsersCount
      : usersFilledBioData.usersCount;
  const withoutBioCount = totalUsers - usersFilledBioData.usersCount;
  const withoutBioPercent = calcPercents(
    totalUsers,
    usersFilledBioData.usersPercent
  );

  return (
    <div className="flex gap-20 w-full border-b border-gray-200 dark:!border-white/10 pb-4">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">
        Users filled bio:
      </h3>
      <div className="flex flex-col gap-1">
        <p>
          <span className="mr-4 font-bold">Filled bio:</span>
          {formatNumber(usersFilledBioData.usersCount)} (
          {formatNumber(usersFilledBioData.usersPercent)}%)
        </p>
        <p>
          <span className="mr-4 font-bold">Not filled bio:</span>
          {formatNumber(withoutBioCount)} ({formatNumber(withoutBioPercent)}%)
        </p>
      </div>
    </div>
  );
};

export const calcUsersAvgTgAge = (usersAvgTgAge: string) => {
  const currentDateTime = new Date();
  const convertedDateTime = new Date(usersAvgTgAge);
  const diff = currentDateTime.getTime() - convertedDateTime.getTime();
  const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));

  return age;
};
