import Select from 'components/fields/Select';
import { useEffect, useState } from 'react';
import { analiticsService } from 'views/services/analitics';
import { useAnaliticsStore } from 'views/store/useAnaliticsStore';
import { UserCountAndPercent } from './UserCountAndPercent';
import { ButtonSM } from 'components/button/Small';
import { TaskCategoriesEnum } from 'views/types/tasks';

type TProps = {
  utmTotalUsers: number;
};

export const UtmTaskSelection = ({ utmTotalUsers }: TProps) => {
  const {
    tasks,
    selectedTaskId,
    setSelectedTaskId,
    setTasksFilterBy,
    id: currentUtmId,
    tasksLoading,
  } = useAnaliticsStore();
  const [filterBy, setFilterBy] = useState<TaskCategoriesEnum | null>(null);
  const [completedUsersCount, setCompletedUsersCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const completedPercentage = (completedUsersCount * 100) / utmTotalUsers;

  const handleTaskSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const taskId = e.target.value;
    setSelectedTaskId(taskId ?? null);
  };

  const handleFilterBy = (category: TaskCategoriesEnum) => {
    const newFilterBy = filterBy === category ? null : category;
    setFilterBy(newFilterBy);
    setTasksFilterBy(newFilterBy);
    setSelectedTaskId(null);
  };

  useEffect(() => {
    if (selectedTaskId) {
      setLoading(true);
      analiticsService
        .getExtraAnalitics({
          dataType: 'points',
          analityctsType: 'utmSource',
          from: 0,
          itemId: currentUtmId,
          taskId: selectedTaskId,
        })
        .then(data => setCompletedUsersCount(data.usersCount))
        .finally(() => setLoading(false));
    }
  }, [currentUtmId, selectedTaskId]);
  return (
    <div>
      <div className="flex gap-1 ml-[184px] mb-1">
        {Object.values(TaskCategoriesEnum).map(category => (
          <ButtonSM
            variant={category === filterBy ? 'primary' : 'secondary'}
            key={category}
            onClick={() => handleFilterBy(category)}
            style={{ padding: '2px 8px', fontSize: '12px' }}
          >
            {category === TaskCategoriesEnum.PARTNERS
              ? 'ONE-TIME TASKS'
              : category}
          </ButtonSM>
        ))}
      </div>

      <div className="flex gap-8 items-end">
        <div className="flex gap-2 items-center">
          <p className="max-w-[180px] min-w-[180px] text-right">
            Task selection:
          </p>

          <Select
            variant="auth"
            extra="max-w-[316px] min-w-[216px]"
            id="taskSelection"
            onChange={handleTaskSelection}
            disabled={tasksLoading}
          >
            <option value="">Select task</option>
            {tasks &&
              tasks.map(task => (
                <option key={task.id} value={task.id}>
                  {task.title}
                </option>
              ))}
          </Select>
        </div>

        {(loading || tasksLoading) && <p>Loading...</p>}

        {selectedTaskId && !loading && (
          <div className="ml-auto min-w-[150px]">
            <UserCountAndPercent
              count={completedUsersCount}
              percent={completedPercentage}
            />
          </div>
        )}
      </div>
    </div>
  );
};
